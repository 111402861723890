import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faTrash, faChartLine, faCloudUploadAlt, faPlus, faRocket, faTasks, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Table, InputGroup, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { CounterWidget, TeamMembersWidget, ProgressTrackWidget, RankingWidget, SalesValueWidget, SalesValueWidgetPhone, AcquisitionWidget } from "../../components/Widgets";
import { PageVisitsTable } from "../../components/Tables";
import { trafficShares, totalOrders } from "../../data/charts";
import Navbar from "../../components/Navbar";
import { check } from "../../checkloggedin"
import { baseurl } from "../../api";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import { fetchProjects } from "../../features/projectslice";
import { useDispatch, useSelector } from "react-redux";
import { faBitbucket } from "@fortawesome/free-brands-svg-icons";
import { getAllBuckets, deleteTaskFromBucket } from "../../features/bucketslice";  // Import your action

export default (props) => {
  let [buckets, setBuckets] = useState([]);  // Initialize buckets state
  const [customers, setCustomers] = React.useState([]);
  const [completedtasks, setcompletedTasks] = useState([]);
  const [incompletedtasks, setincompletedTasks] = useState([]);
  const [contacts, setContacts] = React.useState([]);
  const [bucketsData, setBucketsData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [taskId, setTaskId] = useState('');
  const [tasks, setTasks] = useState([]);
  let [filteredBuckets, setFilteredBuckets] = useState([])

  const username = localStorage.getItem('username');

  const dispatch = useDispatch();
  const { data, call } = props

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault(); // Prevent default only if e is defined
    }
    const userId = check()[0]; // Assuming this returns the userId
    if (!userId) {
      toast.error("User ID is required.");
      return;
    }
    const currentDate = new Date().toISOString().split('T')[0]; // YYYY-MM-DD format
    try {
      // Send taskId as an array if provided, otherwise send an empty array
      const response = await axios.post(`${baseurl}/bucket/bucket/${userId}`, {
        date: currentDate,
        tasks: [], // Send an empty array for tasks
      });
      if (response.status === 200) {
        if (response.data.message === "Tasks added to existing bucket") {
          toast.success("Task added to the existing bucket");
        } else {
          toast.success("New bucket created successfully");
        }
      } else {
        toast.error("Failed to create or update the bucket");
      }
    } catch (error) {
      console.error("Error creating/updating bucket:", error);
      toast.error("Error creating or updating bucket");
    }
  };

  useEffect(() => {
    const mockEvent = { preventDefault: () => { } }; // No-op function
    handleSubmit(mockEvent);



  }, []); // Removed taskId dependency


  useEffect(() => {
    const userId = check()[0]; // Assuming check() returns the user ID
    // Fetching incomplete tasks
    axios.get(`${baseurl}/task/incomplete/${userId}`)
      .then(response => {
        setincompletedTasks(response.data);
      })
      .catch(error => {
        console.error('Error fetching incomplete tasks:', error);
      });
    axios.get(`${baseurl}/task/complete/${userId}`)
      .then(response => {
        setcompletedTasks(response.data);
      })
      .catch(error => {
        console.error('Error fetching complete tasks:', error);
      });
  }, []);


  useEffect(() => {
    const fetchBuckets = async () => {
      try {
        const fetchedBuckets = await dispatch(getAllBuckets());  // Await the dispatch to get the data
        
        setBuckets(fetchedBuckets);   // Set the fetched buckets to local state
        // console.log(fetchedBuckets)
        // Function to filter buckets by the selected date
        let temp = fetchedBuckets.filter(bucket => {
          const bucketDate = formatDate(bucket.date); // Format bucket date as dd-mm-yyyy
          const filterDate = formatDate(selectedDate); // Format selectedDate as dd-mm-yyyy
          return bucketDate === filterDate; // Compare the formatted dates
        });
        console.log(temp)
        setFilteredBuckets(temp)
      } catch (error) {
        toast.error("Error fetching buckets");
      }
    };
    fetchBuckets();  // Call the fetch function
  }, [dispatch]);

  const findTask = (id) => {
    for (let i = 0; i < incompletedtasks.length; i++) {
      if (incompletedtasks[i]._id === id) {
        return incompletedtasks[i];
      }
      console.log(incompletedtasks);
    }
  }

  const handleComplete = async (id, taskCompleted) => {
    try {
      const response = await axios.put(`${baseurl}/task/complete/${id}`);
      const updatedTask = response.data.task;

      if (taskCompleted) {
        // Task was completed, now mark it as incomplete
        setcompletedTasks(prevCompleted =>
          prevCompleted.filter(task => task._id !== id)
        );
        setincompletedTasks(prevIncompleted => [...prevIncompleted, updatedTask]);
        toast.success("Task marked as incomplete");
      } else {
        // Task was incomplete, now mark it as completed
        setincompletedTasks(prevIncompleted =>
          prevIncompleted.filter(task => task._id !== id)
        );
        setcompletedTasks(prevCompleted => [...prevCompleted, updatedTask]);
        toast.success("Task marked as complete");
      }
    } catch (error) {
      console.error("Error updating task status:", error);
      toast.error("Failed to update task status");
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0'); // Ensure two digits for day
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Ensure two digits for month
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };


  const handleDeleteTask = async (taskId) => {
    try {
      // Find the bucket that contains the task to delete
      const bucketWithTask = buckets.find(bucket =>
        bucket.tasks.some(task => task._id === taskId)
      );

      if (!bucketWithTask) {
        toast.error("Task not found in any bucket");
        return;
      }

      // Get the bucket ID and user ID
      const bucketId = bucketWithTask._id;
      const userId = bucketWithTask.user._id; // Assuming the user ID is stored inside the bucket

      // Dispatch the action to delete the task from the backend
      await dispatch(deleteTaskFromBucket(userId, bucketId, taskId));

      // Update the local state by filtering out the deleted task from the bucket
      setBuckets(prevBuckets =>
        prevBuckets.map(bucket => {
          if (bucket._id === bucketId) {
            return {
              ...bucket,
              tasks: bucket.tasks.filter(task => task._id !== taskId)
            };
          }
          return bucket;
        })
      );

      toast.success("Task deleted successfully");

    } catch (error) {
      console.error("Error deleting task:", error);
      toast.error("Failed to delete task");
    }
  };

  const switchstatus = (bucket, taskiddd) => {
    // Log the original bucket and task ID for debugging
    console.log(bucket, taskiddd);

    // Update the bucket's tasks
    const updatedBucket = {
      ...bucket,
      tasks: bucket.tasks.map(task => {
        // Check if the task ID matches the provided taskiddd
        if (task._id === taskiddd) {
          // Toggle the taskcompleted status
          return {
            ...task,
            taskCompleted: !task.taskCompleted,
          };
        }
        // Return the task unchanged if the ID doesn't match
        return task;
      }),
    };

    // Log the updated bucket for debugging
    // filteredBuckets = updatedBucket
    console.log(updatedBucket);
    setFilteredBuckets([updatedBucket])
  }
  return (
    <>
      <ToastContainer />
      <Row className="justify-content-md-center">
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category=" Tasks Completed"
            title={completedtasks.length.toString()}
            icon={faChartLine}
            iconColor="shape-secondary"
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Tasks Incompleted"
            title={incompletedtasks.length.toString()}
            icon={faCashRegister}
            iconColor="shape-tertiary"
          />
        </Col>

        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Bucket"
            title={buckets.length.toString()}
            icon={faBitbucket}
            iconColor="shape-tertiary"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Row>
            <Col className="mb-4">
              <Row>
                <Col xs={12} className="mb-4" style={{ position: 'relative' }}>
                  <h1> Today's Bucket</h1>
                  {(() => {
                    const loggedInUser = localStorage.getItem('username'); // Fetch the logged-in username from local storage
                    if (!loggedInUser) {
                      return <p>No user logged in.</p>;
                    }

                    return (
                      <>
                        {isDatePickerOpen && (
                          <div
                            style={{
                              position: 'absolute',
                              zIndex: 999,
                              backgroundColor: 'white', // Ensure it's visible over the table
                              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional shadow for better visibility
                              padding: '10px', // Optional padding
                              borderRadius: '5px', // Optional rounded corners
                            }}
                          >
                            <DatePicker
                              selected={selectedDate}
                              onChange={(date) => {
                                setSelectedDate(date); // Update the selected date
                                setDatePickerOpen(false); // Close the date picker after selecting a date
                              }}
                              inline
                              dateFormat="yyyy/MM/dd"
                              onClickOutside={() => setDatePickerOpen(false)} // Close on outside click
                            />
                          </div>
                        )}
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Task Subject</th>
                              <th>Task Description</th>
                              <th onClick={() => setDatePickerOpen(!isDatePickerOpen)} style={{ cursor: 'pointer' }}>Date</th>
                              <th>Username</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredBuckets.length === 0 ? (
                              <tr>
                                <td colSpan={5}>No data available</td>
                              </tr>
                            ) : (
                              filteredBuckets
                                .filter(bucket => bucket.user.username === username) // Filter by username from localStorage
                                .map(bucket => (
                                  bucket.tasks.length === 0 ? (
                                    <tr key={bucket._id}>
                                      {/* <td>{bucket._id}</td> */}
                                      {/* <td>{formatDate(bucket.date)}</td> */}
                                      {/* <td>{bucket.user.username}</td>  */}

                                    </tr>
                                  ) : (
                                    bucket.tasks.map(task => (
                                      <tr key={task._id}>
                                        {/* <td>{bucket._id}</td> */}
                                        <td>{task.taskSubject}</td>
                                        <td style={{ whiteSpace: "pre-wrap" }}>{task.taskDescription}</td>
                                        <td>{formatDate(bucket.date)}</td>
                                        <td>{bucket.user.username}</td>
                                        <td>
                                          <Button variant="danger" className="me-2" onClick={() => handleDeleteTask(task._id)} >
                                            Delete
                                          </Button>
                                          <Button
                                            style={{ color: "grey" }}
                                            onClick={() => {
                                              handleComplete(task._id, task.taskCompleted)
                                              switchstatus(bucket, task._id)
                                              console.log(task.taskCompleted)
                                            }}
                                          >
                                            {task.taskCompleted ? "Mark Incomplete" : "Mark Complete"}
                                          </Button>
                                        </td>
                                      </tr>
                                    ))
                                  )
                                ))
                            )}
                          </tbody>
                        </Table>
                      </>
                    );
                  })()}
                </Col>
              </Row>
              <br />
              <br />
              <br />
              <br />
              <br />
              <Row>
                <Col
                  xs={12} className="mb-4">
                  <h1>Task Incomplete</h1>
                  <PageVisitsTable
                    data={incompletedtasks}
                    call={handleComplete}
                  />
                </Col>
              </Row>
              <br />
              <br />
              <br />
              <br />
              <br />
              <Row>
                <Col xs={12} className="mb-4">
                  <h1>Task Completed</h1>
                  <PageVisitsTable
                    data={completedtasks}
                    call={handleComplete}
                  />

                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
