

// Shubham
// const baseurl="https://shmy0ykpgi.execute-api.ap-south-1.amazonaws.com/prod"
// Devoffice
// const baseurl="https://n8i73ay4q0.execute-api.ap-south-1.amazonaws.com/prod"
// Rekha New
const baseurl="https://i5aigtsqzf.execute-api.ap-south-1.amazonaws.com/prod"
// Local
// const baseurl="http://localhost:8000"

const toi = ["Fees", "Services"]
const types = ["Lumpsum", "Percentage", "On Sqft"]
const banknames = ['Misc', 'Bandhan-20100018657972', 'Bharat-612100014610', 'Bharat-610100084505', 'DNS-29010100001263', 'HDFC-1451050122678', 'HDFC-501000174801181']
const ProjectStatus = ["Active", "Inactive", "Working", "Working Active", "Pre-Appointment", "Completed"]
const Agency = ['SRA', 'Mhada', 'General', 'PMC']
const companies = ["Neo", "BZ", "PMC", "VBCPL", "VBAPL", "None of the Above"]
const tds = ['No TDS Cut', 'TDS cut but not Paid', "TDS Claimed"]
const gst = ['GST not Charged', "GST Charged to be Paid", "GST Paid", "GST to be Claimed", "GST Claimed"]
const wards = ["A", "B", "C", "D", "E", "F/N", "F/S", "G/N", "G/S", "H/E", "H/W", "K/E", "K/W", "L", "M/E", "M/W", "N", "P/N", "P/S", "R/N", "R/S", "R/C", "S", "T"]
let Acknowledgement = ["Not Required", "Uploaded", "Not Recieved"]
let Forward = ["No Action Required", "Action Pending", "Action Taken"]
let Type = ["Circular", "Letter"]
export { baseurl, toi, ProjectStatus, banknames, types, Agency, tds, gst, wards, companies, Acknowledgement, Forward, Type }

// https://jiycm07tpk.execute-api.ap-south-1.amazonaws.com/prod

{/* <Form.Check
                              type="checkbox"
                              label="Action Taken"
                              checked={forwarded === "Action Taken"}
                              onChange={(e)=>setForwarded("Action Taken")}
                            />
                             <Form.Check
                              type="checkbox"
                              label="Action Pending"
                              checked={forwarded === "Action Pending"}
                              onChange={(e)=>setForwarded("Action Pending")}
                            />
                             <Form.Check
                              type="checkbox"
                              label="No Action Required"
                              checked={forwarded === "No Action Required"}
                              onChange={(e)=>setForwarded("No Action Required")}
                            /> */}